import { Routes, Route } from "react-router";
import NewOrders from "./NewOrders";
import { OrdersProvider } from "./OrdersContext";
import OrdersNav from "./OrdersNav";
import CompletedOrders from "./CompletedOrders";
import UpNext from "../upNext/UpNext";

const OrdersRoutes = () => (
	<OrdersProvider>
		<div className="orders-container d-flex flex-column h-100 overflow-hidden">
			<div className="orders-body flex-fill overflow-auto d-flex flex-column">
				<Routes>
					<Route element={<CompletedOrders />} path="/completed" />
					<Route element={<UpNext />} path="/up-next" />
					<Route element={<NewOrders />} path="/" />
				</Routes>
			</div>
			<OrdersNav />
		</div>
	</OrdersProvider>
);

export default OrdersRoutes;
