import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import PieCounter from "../../components/PieCounter";
import { useOrders } from "./OrdersContext";
import { useMemo } from "react";

const OrdersNav = () => {
	const { orders } = useOrders();

	const pieCount = useMemo(() => orders ? orders.filter(({ status }) => status !== null).reduce((prev, ord) => prev + ord.line_items.reduce((cnt, item) => cnt + parseInt(item.quantity, 10), 0), 0) : 0, [orders]);

	return (
		<Navbar bg="dark">
			<Nav className="d-flex w-100" variant="dark">
				<Nav.Link as={Link} className="flex-fill text-light text-center" to='/'>New Orders</Nav.Link>
				<PieCounter count={pieCount} />
				<Nav.Link as={Link} className="flex-fill text-light text-center" to="/completed">Completed</Nav.Link>
			</Nav>
		</Navbar>
	);
}

export default OrdersNav;
