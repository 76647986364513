import PropTypes from "prop-types";

import "./PieCounter.scss";

const PieCounter = ({ count }) => (
	<div className="pie-counter-container">
		<div className="pie-counter">
			<div className="pie-counter-inner">
				{count}
			</div>
		</div>
	</div>
);

PieCounter.propTypes = {
	count: PropTypes.number
};

PieCounter.defaultProps = {
	count: 0
};

export default PieCounter;
