import { ListGroup } from 'react-bootstrap';

import OrderItem from './OrderItem';

const OrdersList = ({
	orders
}) => {
	return (
		<ListGroup>
			{orders
				.map((order) => <OrderItem key={order.id} order={order} />)}
		</ListGroup>
	);
};

export default OrdersList;
