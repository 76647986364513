import { Container, Form, Spinner } from "react-bootstrap";
import { useSettings } from "../../components/SettingsProvider";
import { useCallback, useState } from "react";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

const SettingsPage = () => {
	const {isLoading, settings, setSetting} = useSettings();
	const [changedSettings, setChangedSettings] = useState({});
	const [loading, setLoading] = useState(false); 

	const handleChange = useCallback((evt) => {
		evt.preventDefault();

		setChangedSettings((prev) => ({
			...prev,
			[evt.target.name]: evt.target.value
		}))
	}, [setChangedSettings]);
	
	const handleSave = useCallback(async () => {
		setLoading(true);
		for ( var name in changedSettings ) {
			await setSetting({
				name,
				value: changedSettings[name]
			})
		}
		setLoading(false);
	}, [changedSettings, setSetting]);

	return (
		<Container>
			<h1>Settings</h1>

			{isLoading
				? <Spinner animation="border" />
				: (
					<Form onSubmit={handleSave}>
						<h2>Text Message Settings</h2>
						<Form.Group>
							<Form.Label>Text Message - Pizza Started</Form.Label>
							<Form.Control as="textarea" name="sms-pizza-started-text" onChange={handleChange} defaultValue={settings['sms-pizza-started-text']} />
						</Form.Group>
						<ButtonWithSpinner loading={loading} type="submit">Save Options</ButtonWithSpinner>
					</Form>
				)}
		</Container>
	)};

export default SettingsPage;
