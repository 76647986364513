import { Button, ButtonGroup, ListGroup, Spinner } from "react-bootstrap";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { getOrderPerson, getOrderPhone } from "../../helpers/orders";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";

const CreateOrderItem = ({ order }) => {

	const [loading, setLoading] = useState(false);

	const createOrder = useCallback(async () => {
		setLoading(true);
		try {;
			await axios.post(
				(process.env.REACT_APP_FUNCTIONS_BASE || '') + '/square/create-order',
				order,
				{
					headers: {
						'Content-Type': 'application/json'
					}
				}
			);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, [order]);

	const orderName = useMemo(() => getOrderPerson(order), [order]);
	const orderPhone = useMemo(() => getOrderPhone(order), [order]);

	return (
		<ListGroup.Item className="text-start d-flex">
			<div className="flex-fill">
				<ul>
					{order.line_items.map(item => (
						<li key={item.uid}>
							{`${item.name} x ${item.quantity} - ${item.note || order.note || orderName}`}
						</li>
					))}
				</ul>
				<p><DevicePhoneMobileIcon /> {orderPhone}</p>
			</div>
			<ButtonGroup>
				<Button disabled={loading} onClick={createOrder}>
					{ loading ? <Spinner animation="border" size="sm" /> : 'Place Order' }
				</Button>
			</ButtonGroup>
		</ListGroup.Item>
	);
};

export default CreateOrderItem;
