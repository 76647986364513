import { Routes, Route } from 'react-router';
import { getFirestore } from 'firebase/firestore';
import { FirestoreProvider, useFirebaseApp } from 'reactfire';

import './App.scss';

import TopBar from './components/TopBar';
import OrdersRoutes from './pages/orders/OrdersRoutes';
import CreateOrders from './pages/debug/CreateOrders';
import SettingsRoutes from './pages/settings/SettingsRoutes';
import { SettingsProvider } from './components/SettingsProvider';


function App() {
	const firestoreInstance = getFirestore(useFirebaseApp());
	return (
		<FirestoreProvider sdk={firestoreInstance}>
			<SettingsProvider>
				<div className="App d-flex flex-column" style={{height: "100vh", overflow: "hidden"}}>
					<TopBar />
					<div className="app-body flex-fill overflow-auto p-0">
							<Routes>
								<Route element={<CreateOrders />} path="/debug" />
								<Route element={<SettingsRoutes />} path="/settings/*" />
								<Route element={<OrdersRoutes />} path="/*" />
							</Routes>
					</div>
				</div>
			</SettingsProvider>
		</FirestoreProvider>
	);
}

export default App;
