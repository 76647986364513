import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import defaultSMSSetting from "../assets/data/smsSettings.json";

const SettingsContext = createContext(null);
const smsSettingsKey = 'smsSettings';

export const SettingsProvider = ({ children }) => {

	const [smsSettings, setSmsSettings] = useState(defaultSMSSetting);
	const handleSetSmsSettings = useCallback((newSmsSettings) => {
		if (newSmsSettings) {
			setSmsSettings(() => newSmsSettings);
			return newSmsSettings;
		} else {
			setSmsSettings(() => defaultSMSSetting);
			return defaultSMSSetting;
		}
	}, [setSmsSettings]);
	useEffect(() => {
		let smsLocalSettings = localStorage.getItem(smsSettingsKey);
		if (smsLocalSettings) {
			try {
				smsLocalSettings = JSON.parse(smsLocalSettings);
				setSmsSettings(smsLocalSettings);
			} catch (error) {
				console.log(error);
			}
		} else {
			setSmsSettings(defaultSMSSetting);
		}
	}, []);
	useEffect(() => {
		localStorage.setItem(smsSettingsKey, JSON.stringify(smsSettings));
	}, [smsSettings])

	const contextProps = useMemo(() => ({
		setSmsSettings: handleSetSmsSettings,
		smsSettings
	}), [smsSettings, handleSetSmsSettings]);

	return (
		<SettingsContext.Provider value={contextProps}>
			{children}
		</SettingsContext.Provider>
	);
};

export const useSettings = () => useContext(SettingsContext);