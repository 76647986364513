import { Badge, Container, Spinner } from "react-bootstrap";

import OrdersList from "../../components/OrdersList";
import { useOrders } from "./OrdersContext";
import { useMemo } from "react";

const NewOrders = () => {
	const { isLoading, orders } = useOrders();

	const newOrders = useMemo(() => {
		if (orders) {
			return orders.filter((order) => order.status === 'PROPOSED')
		}
		return [];
	}, [orders]);

	const inProgressOrders = useMemo(() => {
		if (orders) {
			return orders.filter((order) => order?.status === "RESERVED");
		}
		return []
	}, [orders]);

	return isLoading
		? <Spinner animation="border" />
		: (
			<>
				<Container className="mb-2 bg-warning py-3" fluid>
					<h5>In Progress <Badge bg="dark">{inProgressOrders.length}</Badge></h5>
					<OrdersList orders={inProgressOrders} />
				</Container>
				<Container fluid>
					<h5>New Orders <Badge bg="dark">{newOrders.length}</Badge></h5>
					<OrdersList orders={newOrders} />
				</Container>
			</>)
};

export default NewOrders;
