import { ListGroupItem } from "react-bootstrap"
import OrderDetails from "./OrderDetails";
import OrderItemActions from "./OrderItemActions";

const OrderItem = ({
	order
}) => (
		<ListGroupItem className="d-sm-block d-md-flex p-0 pb-sm-2 pb-md-0" key={order.id}>
			<OrderDetails order={order} />
			<OrderItemActions order={order} />
		</ListGroupItem>
	);

export default OrderItem;
