import { Alert, Button, Container, Form } from "react-bootstrap";
import { useSettings } from "../../components/SettingsProvider";
import { useCallback, useMemo, useState } from "react";
import { FolderIcon } from "@heroicons/react/24/solid";

const formatLabel = (slug) => slug.split('-').join(' ').toUpperCase();

const SMSSettings = () => {
	const { smsSettings, setSmsSettings } = useSettings();
	const [settings, setSettings] = useState(smsSettings);
	const [messages, setMessages] = useState([]);
	const handleChange = useCallback((evt) => {
		setSettings((prev) => ({
			...prev,
			messages: {
				...prev.messages,
				[evt.target.name]: {
					message: evt.target.value
				}
			}
		}));
	}, [setSettings]);
	const handleSave = useCallback(() => {
		setMessages([]);
		setSmsSettings(settings);
		setMessages([{message: "Message settings saved.", type: "success" }]);
	}, [setSmsSettings, settings]);
	const handleReset = useCallback(() => {
		setMessages(() => []);
		const newSettings = setSmsSettings(null);
		setSettings(() => newSettings);
		setMessages(() => [{message: "Messages reset to system default.", type: "success" }]);
	}, [setSmsSettings]);

	const settingsEntries = useMemo(() => {
		return Object.entries(settings.messages);
	}, [settings.messages]);

	return (
		<Container className="py-4">
			<h1>SMS Settings</h1>
			{
				messages.length
					? (
						<div className="message-container mb-4">
							{messages.map((message) => <Alert variant={message.type}>{message.message}</Alert>)}
						</div>
					)
					: null
			}
			

			<div className="mb-4 sms-messages">
				{settingsEntries.map(([key, value]) => (
					<Form.Group className="card mb-4" key={key}>
						<Form.Label className="card-header">{formatLabel(key)}</Form.Label>
						<Form.Control as="textarea" className="card-body" name={key} onChange={handleChange} value={value.message} />
					</Form.Group>
				))}
			</div>

			<div className="d-flex justify-content-between">
				<Button onClick={handleReset} size="lg" variant="secondary">Reset to Default</Button>
				<Button onClick={handleSave} size="lg" variant="success"><FolderIcon /> Save Changes</Button>
			</div>
		</Container>
	);
}

export default SMSSettings;
