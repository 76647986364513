import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import { useCallback, useEffect, useMemo, useState } from 'react';

import ButtonWithSpinner from './ButtonWithSpinner';
import { sendSms } from '../helpers/twilio';
import { useSettings } from './SettingsProvider';

const SendSMSModal = ({
	prefill,
	onHide,
	order,
	show
}) => {
	const { smsSettings } = useSettings();

	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		to: '',
		body: ''
	});
	useEffect(() => {
		setValues({
			to: order.phone,
			body: smsSettings.messages[prefill]?.message || ''
		});
	}, [order, setValues, prefill, smsSettings]);

	const handleChange = useCallback((evt) => {
		setValues((prev) => ({
			...prev,
			[evt.target.name]: evt.target.value
		}));
	}, [setValues]);

	const validPhone = useMemo(() => values.to ? values.to.match(/\+1\d{10}$/g)?.length === 1 : false, [values.to]);
	const validMessage = useMemo(() => values.body.length > 10, [values.body]);
	const canSend = useMemo(() => validPhone && validMessage, [validPhone, validMessage]);

	const sendMessage = useCallback(async (evt) => {
		evt.preventDefault();

		setLoading(true);
		try {
			const {to, body} = evt.target;

			if (!body || !to) {
				setLoading(false);
				return;
			}

			await sendSms(to.value, body.value);
			setLoading(false);
			onHide();
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, [setLoading, onHide]);

	return (
		<Modal id="sms-modal" centered onHide={onHide} show={show}>
			<Modal.Header closeButton>SMS for {order.name}</Modal.Header>
			<Form onSubmit={sendMessage}>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Phone Number</Form.Label>
						<Form.Control
							isInvalid={!validPhone}
							isValid={validPhone}
							name="to"
							onChange={handleChange}
							value={values.to}
						/>
						{!validPhone
							? <Form.Control.Feedback type='invalid'>Not a Valid Phone Number</Form.Control.Feedback>
							: null
						}
					</Form.Group>

					<Form.Group>
						<Form.Label>Message</Form.Label>
						<Form.Control
							as="textarea"
							isInvalid={!validMessage}
							isValid={validMessage}
							name="body"
							onChange={handleChange}
							value={values.body}
						/>
						{validMessage
							? null
							: <Form.Control.Feedback type='invalid'>Please write a longer message.</Form.Control.Feedback>
						}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-between">
					<Button onClick={onHide} size='lg' style={{visibility: loading ? 'hidden' : 'visible'}} variant="danger"><XMarkIcon /> Cancel</Button>
					<ButtonWithSpinner disabled={!canSend} loading={loading} size='lg' type="submit" variant="success"><CheckIcon /> Send</ButtonWithSpinner>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

SendSMSModal.propTypes = {
	prefill: PropTypes.string,
	onHide: PropTypes.func,
	order: PropTypes.shape({
		phone: PropTypes.string
	}).isRequired,
	show: PropTypes.bool
};

SendSMSModal.defaultProps = {
	prefill: "order-started",
	onHide: null,
	show: false
};

export default SendSMSModal;
