import axios from "axios";

const twilioUrl = () => [
	`https://api.twilio.com/2010-04-01/Accounts`,
	process.env.REACT_APP_TWILIO_ID,
	'Messages.json'
].join('/');

const authHeader = () => `Basic ${btoa([process.env.REACT_APP_TWILIO_ID, process.env.REACT_APP_TWILIO_AUTH].join(':'))}`;

export const sendSms = async (to, message) => {
	try {
		return await axios.post(
			twilioUrl(),
			encodeURI(`To=${to}&Body=${message}&From=${process.env.REACT_APP_TWILIO_NUMBER}`),
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': authHeader()
				}
			}
		);
	} catch (error) {
		console.log(error);
	}
}