import axios from "axios";
import { collection, doc, orderBy, query, updateDoc } from "firebase/firestore";
import { where } from "firebase/firestore/lite";
import { createContext, useCallback, useContext, useMemo, useState } from "react"
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import SendSMSModal from "../../components/SendSMSModal";

const OrdersContext = createContext(null);

const statusTriggerSms = {
	RESERVED: 'order-started',
	PREPARED: 'order-completed',
	COMPLETED: 'order-picked-up'
};

export const OrdersProvider = ({ children }) => {
	const firestore = useFirestore();
	const ordersCollection = collection(firestore, process.env.REACT_APP_FB_ORDERS_COLLECTION);
	const ordersQuery = query(ordersCollection, where('status', '!=', null), orderBy('created_at', 'asc'));
	const { status, data: orders } = useFirestoreCollectionData(ordersQuery, {
		idField: 'id'
	});

	const [smsOrder, setSmsOrder] = useState({});
	const [smsOpen, setSmsOpen] = useState(false);
	const [smsMessage, setSmsMessage] = useState('order-started');
	const toggleSmsModal = useCallback(() => setSmsOpen((prev) => !prev), [setSmsOpen]);
	const sendOrderSms = useCallback((order) => {
		setSmsOrder(order);
		setSmsOpen(true);
	}, [setSmsOrder, setSmsOpen])

	const updateOrder = useCallback(async (order) => {
		if (order.id) {
			const orderRef = doc(firestore, process.env.REACT_APP_FB_ORDERS_COLLECTION, order.id);
			await updateDoc(orderRef, order);
		}
	}, [firestore]);

	const setOrderStatus = useCallback(async (order, status, dbOnly = false) => {		
		const orderRef = doc(firestore, process.env.REACT_APP_FB_ORDERS_COLLECTION, order.id);
		if (!dbOnly) {
			try {
				await axios.post(
					(process.env.REACT_APP_FUNCTIONS_BASE || '') + '/square/update-status',
					{id: order.id, status},
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				);
			} catch (error) {
				console.log(error);
			}
		}
		const updatedDoc = { id: order.id, status };
		if (typeof status === 'string') {
			updatedDoc[`${status.toLowerCase()}_at`] = new Date().toISOString();
		}

		if (Object.keys(statusTriggerSms).indexOf(status) !== -1) {
			setSmsOrder(order);
			setSmsMessage(statusTriggerSms[status]);
			setSmsOpen(true);
		}

		return await updateDoc(orderRef, updatedDoc);
	}, [firestore, setSmsOrder, setSmsOpen]);

	const ordersByStatus = useCallback((status) => orders?.filter((order) => order.status === status) || [], [orders]);


	const contextProps = useMemo(() => ({
		isLoading: status === 'loading',
		orders,
		ordersByStatus,
		sendOrderSms,
		status,
		setOrderStatus,
		updateOrder
	}), [status, orders, sendOrderSms, updateOrder, setOrderStatus, ordersByStatus]);

	return (
		<OrdersContext.Provider value={contextProps}>
			{children}
			<SendSMSModal onHide={toggleSmsModal} order={smsOrder} prefill={smsMessage} show={smsOpen} />
		</OrdersContext.Provider>
	);
};

export const useOrders = () => useContext(OrdersContext);