import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { DevicePhoneMobileIcon, TicketIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ArrowRightOnRectangleIcon, BugAntIcon } from "@heroicons/react/24/solid";

const expand = false;

const TopBar = () => (
	<Navbar bg="dark" className="text-light" expand={expand}>
		<Container className="d-flex justify-content-between" fluid>
			<div />
			<Navbar.Brand as={Link} className="text-center text-white" to="/">Pizza Politano</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="text-white" style={{filter:"invert(1)"}}/>
            <Navbar.Offcanvas
				className="bg-dark"
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
				<Offcanvas.Header className="text-white" closeButton closeVariant="white">
					Menu
				</Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} className="text-white" to="/"><TicketIcon /> Orders</Nav.Link>
                  <Nav.Link as={Link} className="text-white" to="/up-next"><ArrowRightOnRectangleIcon /> Up Next</Nav.Link>
				  <hr />
				  <Nav.Link as={Link} className="text-white" to="/settings/sms"><DevicePhoneMobileIcon /> SMS Settings</Nav.Link>
				  
				  {process.env.REACT_APP_DEBUG ? <><hr /><Nav.Link as={Link} className="text-white" to="debug"><BugAntIcon /> Debug</Nav.Link></> : null}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>

      </Container>
	</Navbar>
);

export default TopBar;
