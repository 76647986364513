import { ClockIcon, PencilSquareIcon, TicketIcon, UserIcon } from "@heroicons/react/24/solid";
import { Col, Row } from "react-bootstrap";
import { getTimeText } from "../helpers/orders";

const OrderDetails = ({ order }) => (
	<div className="order-details flex-fill">
		<Row className="align-items-center p-3 text-start" md={2} xs={1}>
			<Col>
				{order.line_items.map((item) => (
					<div className="fw-bold" key={item.name}>
						<TicketIcon className="text-muted" /> {item.name} &times; {item.quantity}
					</div>
				))}
				{order.note
					? <div><PencilSquareIcon /> {order.note}</div>
					: null
				}
			</Col>
			<Col>
				<div><UserIcon className="text-muted" width={24} /> {order.name}<br />
				<ClockIcon className="text-muted" /> {getTimeText(order)}</div>
			</Col>
		</Row>
	</div>
);

export default OrderDetails;
