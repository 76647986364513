import { Route, Routes } from "react-router";
import SettingsPage from "./SettingsPage";
import SMSSettings from "./SMSSettings";

const SettingsRoutes = () => (
	<Routes>
		<Route element={<SMSSettings />} path="/sms" />
		<Route element={<SettingsPage />} path="*" />
	</Routes>
);

export default SettingsRoutes;
