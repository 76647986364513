import { ListGroup } from 'react-bootstrap';
import testOrders from '../../data/newTestOrderOptions.json';

import CreateOrderItem from './CreateOrderItem';

const CreateOrders = () => (
	<ListGroup>
		{testOrders.map(({ order }) => <CreateOrderItem order={order} key={order.id} /> ) }
	</ListGroup>
);
export default CreateOrders;
