import { Badge, Container, Spinner } from "react-bootstrap";
import { useOrders } from "./OrdersContext"
import OrdersList from "../../components/OrdersList";
import { useMemo } from "react";

const CompletedOrders = () => {
	const { isLoading, ordersByStatus } = useOrders();

	const completedOrders = useMemo(() => ordersByStatus('COMPLETED'), [ordersByStatus]);
	const readyOrders = useMemo(() => ordersByStatus('PREPARED'), [ordersByStatus]);

	return isLoading
		? <Spinner animation="border" />
		: (
			<>
				<Container id="ready-orders" className="mb-2 bg-success py-3" fluid>
					<h3 class="text-white">Ready for Pickup <Badge bg="dark">{readyOrders.length}</Badge></h3>
					<OrdersList orders={readyOrders} />
				</Container>
				<Container fluid>
					<OrdersList orders={completedOrders} />
				</Container>
			</>)
};

export default CompletedOrders;
