import { formatDistance, parseISO } from "date-fns";

export const getOrderPerson = (order) => {
	const fulfillment = order?.fulfillments?.length ? order.fulfillments[0] : null;
	if (fulfillment) {
		const details = fulfillment.type === 'PICKUP' ? fulfillment.pickup_details : fulfillment?.delivery_details || {};
		return details?.recipient?.display_name || '';
	}
	return order?.note || "";
}

export const getOrderPhone = (order) => {
	const fulfillment = order?.fulfillments?.length ? order.fulfillments[0] : null;
	if (fulfillment) {
		const details = fulfillment.type === 'PICKUP' ? fulfillment.pickup_details : fulfillment?.delivery_details || {};
		return details?.recipient?.phone_number || null;
	}
	return null;
}

export const getOrderNote = (order) => {
	const fulfillment = order?.fulfillments?.length ? order.fulfillments[0] : null;
	if (fulfillment) {
		const details = fulfillment.type === 'PICKUP' ? fulfillment.pickup_details : fulfillment?.delivery_details || {};
		return details.note || "";
	}
	return order?.note || "";
}

export const transformSquareOrder = (order) => ({
	created_at: order.created_at,
	id: order.id,
	line_items: order.line_items.map((item) => ({ name: item.name, quantity: item.quantity })),
	name: getOrderPerson(order),
	note: getOrderNote(order),
	phone: getOrderPhone(order),
	status: order.fulfillments[0]?.state || 'PROPOSED'
})

export const getTimeText = (order) => {
	switch(order.status) {
		case 'COMPLETED':
			if (order.reserved_at && order.completed_at) {
				return 'Completed in ' + formatDistance(parseISO(order.reserved_at), parseISO(order.completed_at))
			} else {
				return formatDistance( parseISO(order.created_at), new Date(), { addSuffix: true });
			}
		default:
			return formatDistance( parseISO(order.created_at), new Date(), { addSuffix: true });
	}
}