import { Button, ButtonGroup } from "react-bootstrap";
import { useOrders } from "../pages/orders/OrdersContext";
import { useCallback, useState } from "react";
import ButtonWithSpinner from "./ButtonWithSpinner";
import { ArchiveBoxArrowDownIcon, CheckIcon, ClockIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";

const OrderItemActions = ({ order }) => {
	const { setOrderStatus, sendOrderSms } = useOrders();
	const [loading, setLoading] = useState(false);

	const toggleShowSms = useCallback(() => sendOrderSms(order), [sendOrderSms, order]);

	const handleStart = useCallback(async () => {
		setLoading(true);

		try {
			await setOrderStatus(order, 'RESERVED');
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, [order, setOrderStatus]);

	const handleReadyForPickup = useCallback(async () => {
		setLoading(true);
		try {
			await setOrderStatus(order, 'PREPARED');
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, [order, setOrderStatus]);

	const handleComplete = useCallback(async () => {
		setLoading(true);
		try {
			await setOrderStatus(order, 'COMPLETED');
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, [order, setOrderStatus]);

	const handleReset = useCallback(async () => {
		setLoading(true);
		try {
			await setOrderStatus(order, 'PROPOSED');
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, [order, setOrderStatus]);

	const handleArchive = useCallback(async() => {
		setLoading(true);
		if (window.confirm(`Are you sure you want to archive this order for "${order.name}"?`)) {
			try {
				await setOrderStatus(order, null, true);
			} catch (error) {
				console.log(error);
			}
		}
		setLoading(false);
	}, [order, setOrderStatus]);

	return (
		<>
			<ButtonGroup className="mb-sm-2 mb-md-0">
				{order.phone ? <Button onClick={toggleShowSms}><DevicePhoneMobileIcon /> SMS</Button> : null}
				{
					order.status === 'RESERVED'
						? (
							<>
							<ButtonWithSpinner loading={loading} onClick={handleReset} variant="link"><XMarkIcon /> Cancel</ButtonWithSpinner>
								<ButtonWithSpinner className="px-3" loading={loading} onClick={handleReadyForPickup} variant="success"><CheckIcon /> Complete</ButtonWithSpinner>
							</>
						)
						: order.status === 'PREPARED'
							? <ButtonWithSpinner loading={loading} onClick={handleComplete}>Picked Up</ButtonWithSpinner>
							: order.status === 'PROPOSED'
								? <ButtonWithSpinner className="px-3" loading={loading} onClick={handleStart}><ClockIcon /> Start</ButtonWithSpinner>
								: <ButtonWithSpinner onClick={handleArchive}><ArchiveBoxArrowDownIcon /> Archive</ButtonWithSpinner>
				}
			</ButtonGroup>
		</>
	);
};

export default OrderItemActions;
