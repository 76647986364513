import { useMemo } from "react";
import { useOrders } from "../orders/OrdersContext"
import { Container } from "react-bootstrap";

const UpNext = () => {
	const { ordersByStatus } = useOrders();

	const progressOrders = useMemo(() => ordersByStatus('RESERVED'), [ordersByStatus]);
	const readyOrders = useMemo(() => ordersByStatus('PREPARED'), [ordersByStatus]);

	return (
		<Container className="bg-dark text-white h-100 d-flex flex-column align-items-center justify-content-center" fluid>
			<div className="mb-5">
				<h1>Ready</h1>
				{readyOrders.map(({ name }) => <p>{name}</p>)}
			</div>
			<hr />
			<div>
				<h3>Up Next</h3>
				{progressOrders.map(({ name }) => <p>{name}</p>)}
			</div>

		</Container>
	);
}

export default UpNext;